<script lang="ts" setup>
useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/img/logo.png',
    },
    {
      id: 'gilroy-webfonts-kit',
      rel: 'stylesheet',
      href: () => '/GilroyWebfontsKit.css',
    },
  ],
})
</script>

<template>
  <div>
    <div
      class="absolute z-[-10] h-[500px] w-full bg-gradient-to-t from-primary-950 to-primary-700"
    ></div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <PvToast />
    <PvConfirmDialog>
      <template #container="slotProps">
        <div class="grid gap-4 p-6">
          <p>{{ slotProps.message.message }}</p>

          <div class="flex justify-end gap-2">
            <PvButton
              :label="slotProps?.message?.rejectProps?.label ?? 'Cancelar'"
              severity="contrast"
              outlined
              @click="slotProps?.rejectCallback()"
            />
            <PvButton
              :label="slotProps?.message?.acceptProps?.label ?? 'Continuar'"
              @click="slotProps?.acceptCallback()"
            />
          </div>
        </div>
      </template>
    </PvConfirmDialog>
  </div>

  <div class="hidden">
    <span class="icon-[ph--chat-centered-dots-fill]" />
    <span class="icon-[ph--pencil-simple-line-fill]" />
    <span class="icon-[ph--book-open-fill]" />
    <span class="icon-[ph--user-rectangle-fill]" />
    <span class="icon-[mdi--map-marker]" />
    <span class="icon-[mdi--whatsapp]" />
    <span class="icon-[ph--phone-fill]" />
    <span class="icon-[ph--envelope-simple-fill]" />
    <span class="icon-[ph--check-circle-bold]" />
    <span class="icon-[ph--check-circle-fill]" />
    <span class="icon-[ph--x-circle-bold]" />
    <span class="icon-[ph--x-circle-fill]" />
    <span class="icon-[ph--info-bold]" />
    <span class="icon-[ph--calendar-dots-fill]" />
    <span class="icon-[ph--gender-intersex-fill]" />
    <span class="icon-[ph--device-mobile-fill]" />
    <span class="icon-[ph--info-fill]" />
    <span class="icon-[mdi--facebook-box]" />
    <span class="icon-[mdi--instagram]" />
    <span class="icon-[mdi--twitter-box]" />
    <span class="icon-[ph--car-fill]" />
    <span class="icon-[ph--seal-check-fill]" />
    <span class="icon-[ph--thumbs-up-fill]" />
    <span class="icon-[ph--shield-check-fill]" />
    <span class="icon-[ph--file-text-fill]" />
    <span class="icon-[ph--bell-simple-ringing-fill]" />
  </div>
</template>

<style></style>
